/* fix the footer menu */

.page-sticky-footer__right {
  width: 230px\0;
}

.page-sticky-footer .page-sticky-footer__right {
  right: 2%;
  width: auto;
}

.footer-legal .menu li {
  &:nth-child(3n + 3) {
    padding-right: 35px;
  }
  padding-right: 35px;
}

.product_brief__desc1-reviews {
  margin-bottom: 0px !important;
}

@media only screen and (max-width: 1024px) and (min-width: 641px) {
  .product_brief__desc1-reviews {
    margin-bottom: 30px !important;
  }
}
/* Fixed MPP header layout on IE8 */

html[data-useragent*='MSIE 8.0'] .product-hero-tout .product-promo-image {
  width: auto !important;
}

.product_brief__button--add-to-bag,
.product_brief__sub-panel-buttons-container {
  width: 205px;
}
/* footer css for ipad portrait */
@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {
  .footer-legal .menu li {
    padding-right: 25px !important;
    &.last {
      margin-top: -57px !important;
      margin-right: 130px !important;
    }
  }
}
/* footer css for ipad landscape */
@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: landscape) {
  .page-navigation .level-3 {
    margin: 5px 0 !important;
  }
}
/* font style */
@font-face {
  font-family: 'Conv_OptimaEL-Text';
  src: url('#{$netstorage-font-path}OptimaDisplay/OptimaDisplay-Roman.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

.header__headline,
.mpp__header,
.mpp__bestfor--headline {
  font-family: 'Optima Display Roman' !important;
}

.brand-renutriv {
  .mpp__header,
  .header__headline {
    font-family: $bb-roman !important;
  }
}
